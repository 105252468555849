@import "../../../../styles/helpers";

.card {
    // border: 1px solid red
    position: absolute;
    top: 15%;
    left: 32%;
    height: 476px !important;
    width: 476px;
    // overflow-y: scroll
    @include t {
        left: 25%; }
    @include m {
        left: 15%; }
    @include a {
        height: 400px !important;
        width: 400px;
        left: 12%; }
    @include s {
        height: 300px !important;
        width: 300px;
        left: 10%; }
 }    // right: 50%

.cardHead {
    margin-bottom: 24px !important;
    @include s {
        margin-bottom: 10px !important; } }
.button {
    padding: 0px 30px !important;
    height: 40px;
    border-radius: 8px;
    @include a;
    padding: 0px 15px !important;
    height: 28px;
    border-radius: 5px;
    span {
        font-size: 13px; } }

.closeButton {
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px !important;
    border-radius: 50% !important;
    position: absolute;
    top: -40px;
    right: -10px;
    background-color: white; }
