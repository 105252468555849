@import "../../../styles/helpers";

.field {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include d {
            margin-bottom: 24px; } } }

.field .fieldInput {
    background-color: white !important; }

.button {
    padding: 0px 30px !important;
    height: 40px;
    border-radius: 8px;
    span {
        font-size: 13px; } }

.smallFields {
    width: 50% !important;
    background-color: transparent !important; }

.fieldset {
    display: flex;
    margin: 0 -0px 0px -0px;
    padding: 0 0px;
    gap: 18px;
    align-items: center;
    background-color: #F4F4F4;

    .fieldContainer {
        width: 100%; }
    .field {
        flex: 0 0 calc(50% - 12px);
        margin: 0 0 0 0px;
        align-self: center; }
    .dropdownField {
 }        // width: 100% !important

    .label {
        margin-bottom: 14px;
        font-size: 12px;
        font-weight: 700;
        color: $n4; }

    .fieldInput {
        background-color: white !important;
        // padding: 0px !important
        font-size: 13px;
        font-weight: 400;
        &:focus {
            border-color: $shades1 !important;
            @include dark {
                box-shadow: inset 0 0 0 2px $n5;
                background: $n8 !important;
                color: $n1; } } } }

.title {
    font-size: 14px; }

.attributes_wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px; }

.attribute_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .addButton {
        padding: 0px !important;
        height: 24px;
        width: 24px;
        margin-right: 0px !important;
        margin-left: 0px !important;
        border-radius: 50% !important;
        background-color: #2A85FF !important;
        display: flex;
        justify-content: center;
        align-items: center; } }

.attributes_table_wrapper {
    height: fit-content;
    overflow-x: hidden; }

.imageInput {
    background-color: white !important; }

.imageFieldWrapper {
    border: 1px dashed #B7B7B7;
    background-color: white; }

.image_section {
    display: flex;
    flex-direction: column;
    gap: 16px; }

// variant section crousel
.slider {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
 }    // border: 1px solid red

.errField {
    color: red !important;
    padding-left: 10px; }

.images_crousel_wrapper {
    width: 100%;
    padding: 0 16px; }

.variation_images {
    width: auto;
    border-radius: 8.79px; }

.variant_img_cont_wrap {
    padding: 0 12px;
    height: 213px !important; }

.variant_image_container {
    border-radius: 8.79px;
    background-color: gray;
    position: relative; }

.delete_btn_container {
    position: absolute;
    top: 6px;
    right: 6px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    cursor: pointer; }
