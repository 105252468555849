@import "../../styles/helpers";

.page {
    padding: 0px 0 0 340px;
    @include x {
        padding: 0px 0 0 300px; }
    @include d {
        padding-left: 96px; }
    @include t {
 }        // overflow: hidden
    @include m {
        padding-left: 0; } }

.sidebar {
    @include m {
        transform: translateX(-100%);
        transition: transform .3s;
        &.visible {
            transform: translateX(0); } } }

.inner {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 96px);
    padding: 40px;
    @include x {
        padding: 24px 24px; }
    @include m {
        padding: 24px 16px; } }

.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    &.wide {
        max-width: 100%; } }

.title {
    margin-bottom: 24px;
    @include t {
        font-size: 30px; }
    @include m {
        font-size: 28px; }
    @include a {
        font-size: 20px; } }


.button {
    width: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    @include t {
        height: 40px; }
    @include a {
        height: 35px;
        width: 80px;
        span {
            font-size: 12px; } } }

.plusIcon {
    margin-right: 2px !important; }

.buttonsContainor {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; }

.backButton {
    background-color: white;
    color: black;
    width: fit-content;
    margin-bottom: 24px;
    &:hover {
        background-color: white; } }


