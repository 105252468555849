@import "../../styles/helpers";

.head {
    display: flex;
    align-items: center;
    margin-bottom: 44px;
    @include t {
        margin-bottom: 32px; }
    @include m {
        flex-wrap: wrap;
        margin-bottom: 24px; }
    .form {
        @include w {
            width: 300px; }
        @include t {
            width: 250px; }
        @include m {
            width: 100%;
            margin: 16px 0 0; } } }

.title {
    margin-right: 24px;
    width: fit-content;
    // width: fit-content !important
    @include m {
        margin-right: 0px !important; } }

.control {
    display: none;
    align-items: center;
    @include t {
        display: none; }
    .button {
        margin-right: 8px; } }

.counter {
    margin-left: 16px;
    @include button2; }

.dropdown {
    min-width: 154px;
    margin-left: auto;
    @include m {
        width: 100%;
        margin-top: 16px; } }

.dropdownHead {
    @include m {
        @include dark {
            background: $n6;
            box-shadow: inset 0 0 0 2px $n6;
            color: $n1; } } }

.nav {
    display: flex;
    margin-left: auto;
    @include t {
        display: none; } }

.link {
    padding: 8px 16px;
    border-radius: 8px;
    @include base1-s;
    color: $n4;
    transition: all .2s;
    &:hover,
    &.active {
        color: $n7;
        @include dark {
            color: $n1; } }
    &.active {
        background: $n3;
        @include dark {
            background: $n6; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.button {
    padding: 0px 30px !important;
    height: 40px;
    border-radius: 8px;
    span {
        font-size: 13px; } }
