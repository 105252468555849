@import "../../styles/helpers";
.section {
    width: 100%;

    display: flex; }
.row {
    display: flex;
    // border: 1px solid red
    width: 100%;
    @include t {
        display: block; } }

.orderActionButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px; }

.button {
    padding: 0px 30px !important;
    height: 40px;
    width: 267px;
    border-radius: 8px;
    span {
        font-size: 15px;
        font-weight: 700; } }

.creditMemoBtn {
    background-color: white !important;
    span {
        color: black; } }

.statusDropdownContainer {
    background-color: white;
    padding: 24px;
    border-radius: 8px; }

.col {
    &:first-child {
        flex: 0 0 calc(60% - 0px);
        width: calc(60% - 0px);
        padding-right: 24px;
        @include w {
            // flex: 0 0 calc(100% - 310px)
 }            // width: calc(100% - 310px)
        @include d {
            // flex: 0 0 calc(100% - 296px)
 }            // width: calc(100% - 296px)
        @include t {
            // width: 100%
            margin-bottom: 24px;
            padding-right: 0;
            flex-shrink: 0;
            flex: 0 0 calc(50% - 0px);
            width: calc(50% - 0px);
            display: flex;
            flex-direction: column;
            gap: 24px; }
        @include a {
            width: 100%; } }
    &:nth-child(2) {
        flex-shrink: 0;
        flex: 0 0 calc(40% - 0px);
        width: calc(40% - 0px);
        display: flex;
        flex-direction: column;
        gap: 24px;
        @include w {
 }            // width: 310px
        @include d {
 }            // width: 296px
        @include t {
            width: 100%; } } }

.row,
.card {
    &:not(:last-child) {
        margin-bottom: 8px; } }
