@import "../../../styles/helpers";
.actions {
    .classActionsHead {
        background: white !important;
        svg {
            fill: red !important; } } }

.control {
    display: flex;
    align-items: center; }

.button {
    // padding: 0px 30px !important
    height: 40px !important;
    width: 267px !important;
    border-radius: 8px !important;

    span {
        font-size: 15px !important;
        font-weight: 700 !important; } }

.deleteProductBtn {
    background-color: white !important;
    span {
        color: red; } }

.classActionsHead {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 40px;
    width: 267px !important;
    border-radius: 8px !important;
    color: red;
    font-size: 15px;
    font-weight: 700; }

.classActionsBody {
    background-color: white !important;
    &:before {
        right: 20px;
        @include dark {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E"); } } }

.actionsOption {
    &:first-child,
    &:nth-child(2) {
        @include t {
            display: flex; } } }
