@import '../../../../styles/helpers';

.card {
    // border: 1px solid red
    position: absolute;
    top: 40%;
    left: 32%;
    // right: 50%
    height: 310px;
    width: 476px;
    @include t {
        left: 25%; }
    @include m {
        left: 15%; }
    @include a {
        width: 350px;
        left: 20%; }
    @include s {
        left: 12%;
        width: 300px; } }

.cardHead {
    margin-bottom: 24px !important; }

.button {
    padding: 0px 30px !important;
    height: 40px;
    border-radius: 8px;
    span {
        font-size: 13px; }
    @include a {
        padding: 0px 20px !important;
        height: 35px; } }

.fieldContainerWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px; }

.closeButton {
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px !important;
    border-radius: 50% !important;
    position: absolute;
    top: -40px;
    right: -10px;
    background-color: white; }
