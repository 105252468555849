@import "../../../../styles/helpers";

.categoryTitle {
    font-size: 20px;
    font-weight: 600;
    color: black; }

.item {
    display: flex;
    width: 100%;
    align-items: center;
    transition: color .2s;
    cursor: pointer;
    @include m {
        align-items: stretch; } }

.color {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    border-radius: 4px;
    margin-right: 4px; }

.control {
    transition: all .2s; }

.controlsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: fit-content; }

.control {
    visibility: hidden;
    z-index: 3; }

.line {
    display: flex;
    align-items: flex-start; }

.bannerTitleContainer {
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    z-index: 3;
    display: flex;
    justify-content: center !important;
    gap: 10px;
    transform: translate(0%,-50%);
    align-items: center;
    width: 100%;
    text-align: center; }

.title {
    // margin-right: auto
    color: white;
    font-size: 14px;
    z-index: 3 !important;
    max-width: 70%;
    font-weight: 700;
    visibility: hidden; }

.preview {
    position: relative;
    height: 86px;
    margin-bottom: 6px;
    border-radius: 12px !important;
    overflow: hidden;
    width: 308px;
    flex: 0 0 calc(35% - 26.8px);
    @include w {
        flex: 0 0 calc(35% - 26px) !important; }
    @include t {
        flex: 0 0 calc(35% - 22.5px) !important; }
    @include y {
        flex: 0 0 calc(52% - 19px) !important; }
    @include m {
        flex: 0 0 calc(52% - 20px) !important; }
    @include a {
        flex: 0 0 calc(52% - 18px) !important; }
    @include s {
        flex: 0 0 calc(100%) !important; }
    &:hover {
        .preview:after,.title,
        .control {
            visibility: visible;
            opacity: 1; }
        &:after {
            background: rgba($n8, .8); } }
    &:after,
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .2s; }
    &:after {
 }        // background: rgba($n8, .8)
    &:before {
        z-index: 2;
        border: 2px solid transparent;
        border-radius: 12px;
        pointer-events: none; }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
