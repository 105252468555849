@import "../../../../../styles/helpers";

.row {
    display: table-row;
    transition: background .2s;
    @include t {
        position: relative;
        display: block; }
    &:hover {
        @include rmin(1024) {
            background: $n2;
            @include dark {
                background: $n6; }
            .number,
            .status {
 }                // background: $n1
            .number {
                @include dark {
                    background: $n5; } }
            .status {
                @include dark {
                    background: $n7; } }
            .control {
                visibility: visible;
                opacity: 1;
                z-index: 22; } } }
    &:not(:last-child) {
        @include t {
            margin-bottom: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid $n3;
            @include dark {
                border-color: $n6; } } } }

.col {
    position: relative;
    display: table-cell;
    vertical-align: top;
    padding: 16px 12px;
    color: $n7;
    @include t {
        position: static;
        display: flex;
        align-items: center;
        padding: 0;
        padding-top: 20px; }
    @include dark {
        color: $n1; }
    &:not(:first-child):not(:nth-child(2)) {
        width: 10%;
        @include t {
            width: 100%; } }
    &:first-child {
        // width: 56px
        width: 400px;
        border-radius: 8px 0 0 8px;
        // font-size: 0
        @include t {
            width: fit-content;
 }            // display: none
        &:after {
            left: 12px; } }
    &:nth-child(4) {
        @include t {
 } }            // display: none
    &:not(:first-child):not(:nth-child(2)) {
        @include t {
            padding-top: 0; } }
    &:last-child {
        border-radius: 0 8px 8px 0;
        &:after {
            right: 12px; } }
    &:not(:first-child):not(:last-child) {
        @include t {
            margin-bottom: 12px; } } }

.skuContainer {
    width: 200px;
    @include t {
        width: fit-content; } }

.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color .2s;
    @include m {
        align-items: stretch; }
    @include dark {
        color: $n1; }
    &:hover {
        color: $p1; } }

.preview {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 8px;
    overflow: hidden;
    @include t {
        width: 104px; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.details {
    width: 100%; }
.product {
    width: 100%;
    max-width: 100%;
    margin-bottom: 4px;
    @include w {
        font-size: 13px; }
    @include t {
        font-size: 15px !important;
        max-width: 100%;
        margin-bottom: 8px;
        padding-right: 42px;
        @include title2; } }
.wrap {
    @include t {
        display: flex;
        align-items: center; } }

.category {
    @include caption1;
    color: $n4;
    @include t {
        font-size: 15px; }
    @include dark {
        color: $shades1; } }

.sales {
    display: inline-flex;
    align-items: center;
    &:not(:first-child) {
        justify-content: center;
        width: 100%;
        @include t {
            justify-content: flex-start; } } }

.number,
.price {
    display: inline-block;
    padding: 0 8px;
    border-radius: 6px;
    background: $n3;
    transition: background .2s;
    text-align: center;
    @include w {
        font-size: 13px; }
    @include t {
        font-size: 15px !important; }
    @include dark {
        background: $n5;
        color: $n1; } }

.price {
    display: none;
    @include t {
        display: inline-block;
        margin-right: 12px; } }

.balance {
    margin-left: 8px; }

.box {
    display: flex;
    align-items: center; }

.line {
    position: relative;
    flex-shrink: 0;
    width: 44px;
    height: 8px;
    margin-left: 8px;
    @include d {
        width: 36px; }
    @include t {
        width: 44px; } }

.progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 2px; }

.status {
    transition: background .2s;
    text-align: center; }

.control {
    position: absolute;
    left: 12px;
    bottom: 16px;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    z-index: 22;
    @include t {
        top: 0;
        left: auto;
        right: 0;
        bottom: auto;
        visibility: visible;
        opacity: 1; } }

.label {
    display: none;
    @include t {
        display: block;
        flex-shrink: 0;
        width: 124px;
        padding-right: 20px;
        @include caption1;
        color: $n4; } }
