@import "../../../../styles/helpers";

.row {
    display: table-row;
    transition: background .2s;
    position: relative;
    cursor: pointer;
    @include t {
        position: relative;
        display: block;
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $n3;
        @include dark {
            border-color: $n6; } }
    &:hover {
        @include rmin(1024) {
            background: $n2;
            @include dark {
                background: $n6; } } }

    &:last-child {
        @include t {
            .col {
                padding-bottom: 0;
                &:after {
                    display: none; } } } } }

.col {
    position: relative;
    display: table-cell;
    vertical-align: top;
    padding: 16px 12px;
    width: 12%;
    // border: 1px solid red
    text-align: center;
    color: $n7;
    @include t {
        position: static;
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 12px;
        width: 50%; }
    @include dark {
        color: $n1; }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        background: $n3;
        @include t {
            display: none; }
        @include dark {
            background: $n6; } }
    &:first-child {
        // width: 56px
        border-radius: 8px 0 0 8px;
        // font-size: 0
        @include t {
 }            // display: none
        &:after {
            left: 12px; } }
    &:nth-child(3) {
        @include t {
 } }            // display: none
    &:not(:first-child):not(:nth-child(2)) {
        padding-top: 20px;
        @include t {
            padding-top: 0; } }
    // &:last-child
    //     border-radius: 0 8px 8px 0
    //     position: absolute
    //     padding: 12px 12px !important
    //     top: 0px
    //     right: 0px
    //     width: 20%

    //  &:after
    //      right: 12px
    // &:not(:first-child):not(:last-child)
    //     +t
 }    //         margin-bottom: 12px

.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color .2s;
    @include m {
        align-items: stretch; }
    @include dark {
        color: $n1; }
    &:hover {
        color: $p1; } }

.preview {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 8px;
    overflow: hidden;
    @include t {
        width: 104px; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.product {
    max-width: 170px;
    margin-bottom: 4px;
    @include t {
        max-width: 100%;
        margin-bottom: 8px;
        @include title2; } }

.wrap {
    @include t {
        display: flex;
        align-items: center; } }

.category {
    @include caption1;
    color: $n4;
    @include t {
        font-size: 15px; } }

.sales {
    display: inline-flex;
    align-items: center; }

.number,
.price {
    display: inline-block;
    padding: 0 8px;
    border-radius: 6px;
    background: $n3;
    transition: background .2s;
    @include dark {
        background: $n5; } }

.price {
    display: none;
    @include t {
        display: inline-block;
        margin-right: 12px; } }

.status {
    font-weight: 400;
    font-size: 15px;
    transition: background .2s; }

.rating {
    display: flex;
    align-items: center;
    @include base2;
    svg {
        margin-right: 8px;
        fill: #FFC554; }
    span {
        margin-left: 4px;
        color: $n4; } }

.ratingEmpty {
    white-space: nowrap;
    color: $n4;
    svg {
        fill: $n4; } }

.balance {
    margin-left: 8px; }

.box {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    @include t {
        justify-content: flex-start; }
    // border: 1px solid green
    font-weight: 400; }

.line {
    position: relative;
    flex-shrink: 0;
    width: 44px;
    height: 12px;
    margin-left: 8px; }

.progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 2px; }

.label {
    display: none;
    @include t {
        display: block;
        flex-shrink: 0;
        width: 124px;
        padding-right: 20px;
        text-align: start;
        @include caption1;
        color: $n4; } }
