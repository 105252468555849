@import "../../../../styles/helpers";

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    transition: background .2s;
    // position: relative
    @include t {
        // position: relative
        // display: block
        margin-bottom: 24px;
        padding-bottom: 24px;
        // border-bottom: 1px solid $n3
        @include dark {
            border-color: $n6; } }
    &:hover {
        @include rmin(1024) {
            background: $n2;
            @include dark {
                background: $n6; }
            .number,
            .status {
                background: $n1;
                @include dark {
                    background: $n5; } } } }
    &:last-child {
        @include t {
            .col {
                padding-bottom: 0;
                &:after {
                    display: none; } } } } }

.col {
    position: relative;
    display: table-cell;
    vertical-align: top;
    padding: 16px 12px;
    color: $n7;
    @include t {
        position: static;
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 12px;
 }        // width: 50%
    @include dark {
        color: $n1; }
    &:first-child {
        // width: 56px
        border-radius: 8px 0 0 8px;
        // font-size: 0
        @include t {
 }            // display: none
        &:after {
            left: 12px; } }
    &:not(:first-child):not(:nth-child(2)) {
        padding-top: 20px;
        @include t {
            padding-top: 0; } }
    &:last-child {
        border-radius: 0 8px 8px 0; } }


.box {
    display: flex;
    align-items: center;
    // width: 50%
    font-weight: 400; }


.label {
    display: none;
    @include t {
        display: block;
        flex-shrink: 0;
        width: 124px;
        padding-right: 20px;
        @include caption1;
        color: $n4; } }
