@import "../../../styles/helpers";

.card {
    // border: 1px solid red
    position: absolute;
    top: 40%;
    left: 32%;
    // right: 50%
    height: 200px;
    width: 476px;
    @include t {
        width: 350px;
        height: 150px; }
    @include m {
        left: 25%; }
    @include a {
        left: 15%; }
    @include s {
        left: 12%;
        width: 300px; } }


.cardHead {
    margin-bottom: 24px !important; }
.button {
    padding: 0px 40px !important;
    height: 40px;
    border-radius: 8px;
    span {
        font-size: 13px; } }

.errField {
    color: red !important;
    padding-left: 10px; }

.title {
    font-size: 18px; }

.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }

.confirmText {
    font-size: 18px !important;
    font-weight: 400; }
