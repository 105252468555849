@import "../../../styles/helpers";

.description {
    & > .fieldContainer {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include d {
                margin-bottom: 24px; } }
        .label {
            margin-bottom: 14px; } }

    .fieldInput {
        // background-color: transparent !important
        // border: 2px solid rgba(206, 194, 204, 0.25) !important
        font-size: 13px;
        font-weight: 400;
        &:focus {
            border-color: $shades1 !important;
            background-color: white !important;
            @include dark {
                box-shadow: inset 0 0 0 2px $n5;
                background: $n8 !important;
                color: $n1; } } } }

.backButton {
    background-color: white;
    color: black;
    padding: 0px !important;
    width: 95px;
    height: 40px;
    border-radius: 8px;
    border: 2px solid #EFEFEF;
    &:hover {
        background-color: white; }
    span {
        font-size: 13px; }
    @include s {
        width: 80px;
        height: 35px;
        padding: 0px 10px !important; } }

.group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 -6px -12px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(50% - 12px);
        width: calc(50% - 12px);
        margin: 0 6px 12px;
        @include m {
            width: 100%;
            margin: 12px 0 0; } } }

.descriptionLabel {
    color: $n5;
    @include dark {
        color: $n3; } }

.fieldset {
    display: flex;
    margin: 0 -0px 32px -0px;
    align-items: center;
    gap: 24px;
    @include d {
        margin: 0 -0px 24px -0px; }
    @include a {
        gap: 12px; }
    @include s {
        flex-direction: column;
        gap: 24px; }
    .fieldContainer {
        // border: 1px solid red
        width: 100%; }
    .field {
        flex: 0 0 calc(50% - 12px);
        // width: calc(50% - 12px)
        margin: 0 6px;
        align-self: center; }
    .dropdownField {
 }        // width: 100% !important
    .label {
        margin-bottom: 14px;
        font-size: 12px;
        // line-height: (16/12)
        font-weight: 700;
        color: $n4; } }

.field {
    margin-bottom: 5px;
 }    // margin-bottom: 32px

.descriptionField {
    // display: flex
    // flex-direction: column
    // justify-content: flex-start
    height: 160px !important;
    // overflow-y: scroll
    width: 100% !important;
    padding-top: 16px;
    padding-left: 10px !important;
    resize: none;
    border-radius: 12px;
    background: transparent !important;
    border: 2px solid rgba(168, 173, 179, 0.25) !important;
    // height: 100px !important
    // margin-bottom: 32px
    &:focus {
        border-color: $shades1 !important;
        background-color: white !important;
        @include dark {
            box-shadow: inset 0 0 0 2px $n5;
            background: $n8 !important;
            color: white !important; } } }

.label {
    font-size: 14px !important;
    color: $n5 !important;
    font-weight: 600 !important; }

.button {
    padding: 0px 30px !important;
    height: 40px;
    border-radius: 8px;
    span {
        font-size: 13px; }
    @include s {
        padding: 0px 20px !important;
        height: 35px; } }

.errField {
    color: red !important;
    padding-left: 10px; }

.smallFields {
    width: 50% !important;
    @include s {
        width: 100% !important; } }

.smallDropdownField {

    // margin-right: 12px
 }    // margin-left: 12px

.smallDropdownlabel {
    margin-left: 6px; }
