.loading-dialog-container {
    position: absolute;
    top: 10%;
    left: 135px;
    right: 135px;
    background-color: transparent;
    border: none;
    padding: 195px 53px 195px 53px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 44px
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    opacity: 0.2;
}

@media only screen and (max-width: 400px) {
    .loading-dialog-container {
        left: 110px;
    }
}