@import "../../styles/helpers";

.row {
    display: flex;
    gap: 40px;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 340px);
        width: calc(100% - 340px);
        padding-right: 8px;
        @include d {
            flex: 0 0 calc(100% - 296px);
            width: calc(100% - 296px); }
        @include t {
            width: 100%;
            padding: 0;
            &:first-child {
                margin-bottom: 16px; } } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 340px;
        @include d {
            width: 296px; }
        @include t {
            width: auto; } } }

.card {
    &:not(:last-child) {
        margin-bottom: 8px; } }

.orderActionButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px; }

.button {
    padding: 0px 30px !important;
    height: 40px;
    width: 267px;
    border-radius: 8px;
    span {
        font-size: 15px;
        font-weight: 700; } }

.deleteProductBtn {
    background-color: white !important;
    span {
        color: red; } }
