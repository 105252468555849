@import "../../../styles/helpers";

.product {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center
    gap: 11px;
    &.active {
        .checkbox {
            visibility: visible;
            opacity: 1; }
        .preview {
            &:before {
                border-color: none !important; } } } }

// .preview:after,
// .control, .title
//     opacity: 0
//     visibility: hidden
//     transition: all .2s

.preview {
    position: relative;
    height: 86px;
    margin-bottom: 6px;
    border-radius: 12px !important;
    overflow: hidden;
    width: 308px;
    flex: 0 0 calc(35% - 26.8px);
    @include w {
        flex: 0 0 calc(35% - 26px) !important; }
    @include t {
        flex: 0 0 calc(35% - 22.5px) !important; }
    @include y {
        flex: 0 0 calc(52% - 19px) !important; }
    @include m {
        flex: 0 0 calc(52% - 20px) !important; }
    @include a {
        flex: 0 0 calc(52% - 18px) !important; }
    @include s {
        flex: 0 0 calc(100%) !important; }
    &:hover {
        .preview:after,.title,
        .control {
            visibility: visible;
            opacity: 1; }
        &:after {
            background: rgba($n8, .8); } }
    &:after,
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .2s; }
    &:after {
 }        // background: rgba($n8, .8)
    &:before {
        z-index: 2;
        border: 2px solid transparent;
        border-radius: 12px;
        pointer-events: none; }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    // .checkbox
    //     position: absolute
    //     top: 12px
    //     left: 12px
    //     z-index: 3
    //     +t
    //         display: none
    //     .checkboxTick
 }    //         border-color: $p1

.control {
    visibility: hidden;
    z-index: 3;
 }    // transform: translate(-50%,-50%)

.line {
    display: flex;
    align-items: flex-start; }

.bannerTitleContainer {
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    z-index: 3;
    display: flex;
    justify-content: center !important;
    gap: 10px;
    transform: translate(0%,-50%);
    align-items: center;
    width: 100%;
    text-align: center; }

.title {
    // margin-right: auto
    color: white;
    font-size: 14px;
    z-index: 3 !important;
    max-width: 70%;
    font-weight: 700;
    visibility: hidden; }

.price,
.empty {
    flex-shrink: 0;
    display: inline-block;
    margin-left: 32px;
    padding: 0 8px;
    border-radius: 6px;
    line-height: 32px;
    font-weight: 700; }

.price {
    background: $s4;
    @include dark {
        color: $n7; } }

.empty {
    background: $n3;
    @include dark {
        background: $n6;
        color: $n1; } }

.date {
    display: flex;
    align-items: center;
    margin-top: 8px;
    @include base2;
    color: $n4;
    @include m {
        margin-top: 2px; }
    svg {
        margin-right: 8px;
        fill: $n4; } }

.rating {
    display: flex;
    align-items: center;
    margin-top: 8px;
    @include base2;
    @include m {
        margin-top: 4px; }
    svg {
        margin-right: 8px;
        fill: #FFC554; }
    span {
        margin-left: 4px;
        color: $n4; } }

.ratingEmpty {
    color: $n4;
    svg {
        fill: $n4; } }

.control {
    transition: all .2s; }
