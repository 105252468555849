@import "../../styles/helpers";

.label {
    margin-bottom: 14px;
    width: 100%;
    @include base2;
    color: $n5;
    @include dark {
        color: $n3; } }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    border-radius: 12px;
    width: 100%;
    background: $n2;
    overflow: hidden;
    @include dark {
        background: $n6; } }

.input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 340px;
    width: 100%;
    opacity: 0;
    &:hover + .box {
        border-color: $n4;
        @include dark {
            border-color: $n5; } } }

.classInputWraper {
    width: 100% !important;
    border: 1px solid red; }

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 20px;
    border: 2px solid $n3;
    border-radius: 12px;
    background: $n1;
    box-shadow: 0px 12px 13px -6px rgba(0, 0, 0, 0.04);
    @include button1;
    transition: border-color .2s;
    @include dark {
        background: $n7;
        border-color: $n6;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 12px 13px -6px rgba(0, 0, 0, 0.04), inset 0px 2px 4px #31353B; }
    svg {
        margin-right: 8px;
        @include dark {
            fill: $n1; } } }

.url {
    position: absolute;
    z-index: 1;
    top: 10%;
    left: 2%;
    // right: 40%
    // left: 0
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // width: 550px !important
    height: 50px;
    width: 80%;
    text-overflow: ellipsis !important;
    overflow: hidden;
    // border: 1px solid red
 }    // overflow: scroll

.urlText {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    left: 2%; }

.imgPrevievContainer {
    display: flex;
    width: 150px;
    height: 150px;
    justify-content: center;
    margin-bottom: 10px; }

.file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
