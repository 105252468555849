@import "../../../../../styles/helpers";

.market {
    margin: 0 0px;
    width: 100%;
    height: 60%;
    // overflow: hidden
    overflow-y: scroll;
    // border: 1px solid green
    @include t {
        margin: 0; } }

.table {
    display: table;
    width: 100%;
    // overflow-y: scroll
    @include t {
 } }        // display: block

.row {
    display: table-row;
    @include t {
 } }        // display: none

.col {
    position: relative;
    display: table-cell;
    padding: 0 0px 16px;
    vertical-align: middle;
    width: 20%;
    border: none !important;
    // border: 1px solid red
    @include caption1;
    color: $n4;
    // &:after
    //     content: ""
    //     position: absolute
    //     left: 0
    //     bottom: 0
    //     right: 0
    //     height: 1px
    //     background: $n3
    //  @include dark
    //      background: $n6
    &:first-child {
        padding-left: 0px;
        // width: 56px
        // font-size: 0
        &:after {
 } }            // left: 12px
    &:last-child {
        &:after {
            right: 12px; } } }

.foot {
    display: flex;
    justify-content: center;
    margin-top: 24px; }

.arrow {
    width: 40px;
    height: 40px;
    border: 2px solid $n3;
    border-radius: 50%;
    transition: border-color .2s;
    @include dark {
        border-color: transparent; }
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        border-color: $n3;
        svg {
            fill: $n7; }
        @include dark {
            border-color: $n6;
            svg {
                fill: $n1; } } }
    &:not(:last-child) {
        margin-right: 8px; } }

.card {
    // border: 1px solid red
    // margin-bottom: 10px !important
    padding: 0px !important;
    height: 43%;
    // overflow: hidden
    @include t {
        &:not(:last-child) {
 } } }            // margin-bottom: 10px !important

.cardHead {
    margin-bottom: 15px !important;
    @include a {
        margin-bottom: 5px !important; } }

.cardTitle {
    @include a {
 } }        // font-size: 14px !important
