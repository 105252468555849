input[type='checkbox'] {
    display: none;
}

.wrap-collabsible {
    margin: 1.2rem 0;
    /* padding-bottom: 12px; */
}
.variation_title{
    font-size: 14px;
}
.lbl-toggle {
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    /* font-family: monospace; */
    font-size: 14px;
    /* text-transform: uppercase; */
    /* text-align: center; */
    padding: 18px;
    /* color: #DDD; */
    background: #F4F4F4;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.25s ease-out;
    /* border: 1px solid red; */
}

.lbl-toggle:hover {
    /* color: #FFF; */
}

.lbl-toggle::after {
    content: ' ';
    display: none;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    transform: translateY(-4px);
    transition: transform .2s ease-out;
}

.lblToggle::before{
    transform: rotate(0deg);
}


.toggle:checked+.lbl-toggle::after {
    transform: rotate(90deg) translateX(-3px);
}

.collapsible-content {
    max-height: 0px;
    overflow: hidden;
    background-color: #F4F4F4;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 18px;
    padding-right: 18px;
    /* padding-bottom: 18px; */
    transition: max-height .25s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.toggle:checked+.lbl-toggle+.collapsible-content {
    max-height: fit-content !important;
}

.toggle:checked+.lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.collapsible-content .content-inner {
    background: #F4F4F4;
    /* border-bottom: 1px solid rgba(0, 105, 255, .45); */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: .5rem 1rem;
}

.collapsible-content p {
    margin-bottom: 0;
}

/* .slick-slider button{
    margin-left: 10px;
    margin-right: 10px;
    background-color: #2A85FF;
    color: white;
}

.slick-slider button::before{
    background-color: #2A85FF;
    color: white;
}

.slick-slider button::after{
    background-color: #2A85FF;
    color: white;
} */