.slick-prev:before, .slick-next:before {
    color: #2A85FF !important;
    opacity: 0;
    background: transparent !important;
    content: none;
}

.slick-prev, .slick-next {
    color: #2A85FF !important;
    opacity: 100 !important;
    background: transparent !important;
}

.slick-next:hover{
    border: none !important;
    background-color: aquamarine;
}

.slick-prev :hover, .slick-next :hover {
    border: none !important;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
    opacity: 100 !important;
    border: none !important;
}

.slick-next:hover {
    border: none !important;
}

/* .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
    opacity: 100 !important;
} */

.ImagesAndCTA_image_section__11A3o {
    /* padding: 0 16px; */
}