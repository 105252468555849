@import "../../../styles/helpers";

.field {
    // padding: 0 10px
    &:not(:last-child) {
        margin-bottom: 32px;
        @include d {
            margin-bottom: 24px; } } }

.button {
    padding: 0px 30px !important;
    height: 40px;
    border-radius: 8px;
    span {
        font-size: 13px; } }

.smallFields {
    width: 50% !important;
    background-color: transparent !important; }

.fieldset {
    display: flex;
    margin: 0 -0px 0px -0px;
    padding: 0 0px;
    gap: 18px;
    align-items: center;
    background-color: #F4F4F4;

    .fieldContainer {
        // border: 1px solid red
        width: 100%; }
    .field {
        flex: 0 0 calc(50% - 12px);
        // width: calc(50% - 12px)
        margin: 0 0 0 0px;
        align-self: center; }
    .dropdownField {
 }        // width: 100% !important
    .label {
        margin-bottom: 14px;
        font-size: 12px;
        // line-height: (16/12)
        font-weight: 700;
        color: $n4; }

    .fieldInput {
        background-color: white !important;
        padding: 0px !important;
        // border: 2px solid rgba(168, 173, 179, 0.25) !important
        font-size: 13px;
        font-weight: 400;
        &:focus {
            border-color: $shades1 !important;
            // background-color: white !important
            @include dark {
                box-shadow: inset 0 0 0 2px $n5;
                background: $n8 !important;
                color: $n1; } } } }

.title {
    font-size: 14px;
    font-weight: 400; }

.attributes_wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px; }
.attribute_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    .addButton {
        padding: 0px !important;
        height: 24px;
        width: 24px;
        margin-right: 0px !important;
        margin-left: 0px !important;
        border-radius: 50% !important;
        background-color: #2A85FF !important;
        display: flex;
        justify-content: center;
        align-items: center; } }

.imageInput {
    background-color: white !important; }

.imageFieldWrapper {
    border: 1px dashed #B7B7B7;
    background-color: white; }

.image_section {
    display: flex;
    flex-direction: column;
    gap: 16px; }

// variant section crousel
.slider {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
 }    // border: 1px solid red

.images_crousel_wrapper {
 }    // border: 1px solid red !important

.variant_img_cont_wrap {
    width: 100% !important;
    height: 179px !important;
    padding: 0 8px; }

.variant_image_container {
    width: 100% !important;
    height: 179px !important;
    border-radius: 8.79px;
    background-color: gray;
    position: relative; }

.delete_btn_container {
    position: absolute;
    top: 6px;
    right: 6px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    cursor: pointer; }

.attributes_table_wrapper {

    .field {
        margin-bottom: 0px !important;
        width: 100%;
        height: 48px;
        border: 0px !important; }
    .fieldInput {
        border: 0px !important;
        background-color: #F4F4F4 !important;
        &:hover {
            background-color: #F4F4F4 !important;
            border: 0px !important; } } }

.readOnly_field {
    display: flex;
    height: fit-content !important;
    position: relative;
    flex-wrap: wrap;
    margin-bottom: 12px; }
